<template>
    <LiefengContent>
        <template v-slot:title>{{$route.query.name || '报表详情'}}</template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                height="200"
                :loading="loading"
                :fixTable="true"
                :pagesizeOpts="[20, 30, 50, 100]"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: { LiefengTable, LiefengContent },
    data() {
        return {
            talbeColumns: [
                {
                    title: "报表名称",
                    align: "center",
                    minWidth: 180,
                    key: "reportName",
                    render:(h,params) =>{
                        return h('span',{},this.$route.query.name)
                    }
                },
                {
                    title: "时段",
                    align: "center",
                    minWidth: 180,
                    key: "period",
                    render: (h, params) => {
                        return h("span", {}, params.row.period && params.row.period != "" ? params.row.period : "")
                    },
                },
                {
                    title: "最新生成时间",
                    align: "center",
                    minWidth: 180,
                    key: "generateDate",
                    render: (h, params) => {
                        return h("span", {}, params.row.generateDate && params.row.generateDate != "" ? this.$core.formatDate(new Date(params.row.generateDate), "yyyy年MM月dd日") : "")
                    },
                },
                {
                    title: "操作",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "Button",
                            {
                                props: {
                                    type: "primary",
                                    size: "small",
                                },
                                on: {
                                    click: () => {
                                        this.$core.openLayerFrame(
                                            {
                                                type: 2,
                                                title: this.$route.query.name,
                                                content: `/page#/operationreportform?name=${this.$route.query.name}&id=${params.row.id}&time=${this.$core.formatDate(new Date(params.row.generateDate), "yyyy-MM-dd")}&reportCode=${this.$route.query.reportCode}`,
                                                area: ["100%", "100%"],
                                            }
                                        )
                                    },
                                },
                            },
                            "查看报表"
                        )
                    },
                },
            ],
            tableData: [],
            loading: false,
            page: 1,
            pageSize: 20,
            total: 0,
        }
    },
    created() {
        this.getList()
    },
    methods: {
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 接口部分
        getList() {
            this.loading = true
            this.$get("/statistic/api/symanage/pc/report/selectReportDetailPage", {
                page: this.page,
                pageSize: this.pageSize,
                reportId:this.$route.query.id
            }).then(res => {
                this.loading = false
                if (res.code == 200 && res.dataList) {
                    this.tableData = res.dataList.map(item => {
                        if (item.reportJson && item.reportJson != "") {
                            item.reportJson = JSON.parse(item.reportJson)
                        }
                        return {
                            ...item,
                            ...item.reportJson,
                        }
                    })
                    this.total = res.maxCount
                }
            })
        },
    },
}
</script>

<style>
</style>